<template>
  <b-overlay :show="formShow" rounded="sm" no-fade>
    <section class="invoice-preview-wrapper">
      <!-- Alert: No item found -->
      <b-alert variant="danger" :show="invoiceData === undefined">
        <h4 class="alert-heading">
          Error fetching invoice data
        </h4>
        <div class="alert-body">
          No invoice found with this invoice id. Check
          <b-link class="alert-link" :to="{ name: 'apps-invoice-list' }">
            Invoice List
          </b-link>
          for other invoices.
        </div>
      </b-alert>

      <b-row v-if="invoiceData" class="invoice-preview">
        <!-- Col: Left (Invoice Container) -->
        <b-col cols="12" xl="9" md="8">
          <b-card no-body class="invoice-preview-card">
            <!-- Header -->
            <b-card-body class="invoice-padding pb-0">
              <div class="d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0">
                <!-- Header: Left Content -->
                <div>
                  <div class="logo-wrapper">
                    <logo />
                  </div>
                  <p class="card-text mb-25" style="width: 300px;">
                    {{ invoiceData.companyAddress }}
                  </p>

                  <p class="card-text mb-0">{{ invoiceData.companyPhone }} - {{ invoiceData.companyTel }}</p>
                </div>

                <!-- Header: Right Content -->
                <div class="mt-md-0 mt-2">
                  <h4 class="invoice-title">
                    Purchase Order Number
                    <span class="invoice-number">#{{ invoiceData.id }}</span>
                  </h4>
                  <div class="invoice-date-wrapper">
                    <p class="invoice-date-title">
                      Date Issued:
                    </p>
                    <p class="invoice-date">
                      {{ invoiceData.date }}
                    </p>
                  </div>

                  <div class="invoice-date-wrapper">
                    <p class="invoice-date-title">
                      Due Date
                    </p>
                    <p class="invoice-date">
                      {{ invoiceData.dueDate }}
                    </p>
                  </div>

                  <div class="invoice-date-wrapper">
                    <p class="invoice-date-title">
                      Recurrence
                    </p>
                    <p class="invoice-date">
                      {{ invoiceData.recurrence }}
                    </p>
                  </div>

                  <div class="invoice-date-wrapper" v-if="invoiceData.vehicleValue">
                    <p class="invoice-date-title">
                      Vehicle
                    </p>
                    <p class="invoice-date">
                      {{ invoiceData.vehicleValue.stockNumber }} <br />
                      {{ invoiceData.vehicleValue.value }}
                    </p>
                  </div>
                </div>
              </div>
            </b-card-body>

            <!-- Spacer -->
            <hr class="invoice-spacing" />

            <!-- Invoice Client & Payment Details -->
            <b-card-body v-if="invoiceData.get_customer" class="invoice-padding pt-0">
              <b-row class="invoice-spacing">
                <!-- Col: Invoice To -->
                <b-col cols="12" xl="6" class="p-0">
                  <h6 class="mb-2">
                    Vendor
                  </h6>
                  <h6 class="mb-25">{{ invoiceData.get_customer.firstName }} {{ invoiceData.get_customer.lastName }}</h6>
                  <p class="card-text mb-25">{{ invoiceData.get_customer.streetAddress }}</p>
                  <p class="card-text mb-25">{{ invoiceData.get_customer.provence }} {{ invoiceData.get_customer.city }} {{ invoiceData.get_customer.country }}</p>
                </b-col>
              </b-row>
            </b-card-body>

            <!-- Invoice Description: Table -->

            <b-table-lite responsive :items="invoiceData.items" :fields="tableColumns">
              <template #cell(itemTitle)="data">
                <b-card-text class="font-weight-bold mb-25">
                  {{ data.item.itemTitle ? data.item.itemTitle.productName : 'N/A' }}
                </b-card-text>
                <b-card-text>
                  {{ data.item.description }}
                </b-card-text>
              </template>

              <template #cell(cost)="data">
                {{ formatPrice(data.item.cost) }}
              </template>

              <template #cell(vehicle)="data">
                <b-card-text class="font-weight-bold mb-25">
                  {{ data.item.vehicleValue ? data.item.vehicleValue.stockNumber : null }}
                </b-card-text>
                <b-card-text>
                  {{ data.item.vehicleValue ? data.item.vehicleValue.value : 'N/A' }}
                </b-card-text>
              </template>

              <template #cell(gst)="data"> {{ data.item.gst ? '%' + data.item.gst : 'N/A' }} </template>
              <template #cell(pst)="data"> {{ data.item.pst ? '%' + data.item.pst : 'N/A' }} </template>
              <template #cell(discount)="data"> {{ data.item.discount ? '%' + data.item.discount : 'N/A' }} </template>
              <template #cell(total)="data"> {{ formatPrice(lineTotal(data.item.cost,data.item.qty,data.item.gst,data.item.pst,data.item.discount))   }} </template>
            </b-table-lite>

            <!-- Invoice Description: Total -->
            <b-card-body class="invoice-padding pb-0">
              <b-row>
                <!-- Col: Total -->
                <b-col cols="12" md="12" class="mt-md-6 d-flex justify-content-end" order="1" order-md="2">
                  <div class="invoice-total-wrapper">
                    <div class="invoice-total-item">
                      <p class="invoice-total-title">
                        Subtotal:
                      </p>
                      <p class="invoice-total-amount">${{ formatPrice(subTotal) }}</p>
                    </div>
                    <div class="invoice-total-item">
                      <p class="invoice-total-title">
                        Discount:
                      </p>
                      <p class="invoice-total-amount">${{ formatPrice(totalDiscount) }}</p>
                    </div>
                    <div class="invoice-total-item">
                      <p class="invoice-total-title">
                        Tax:
                      </p>
                      <p class="invoice-total-amount">${{ formatPrice(totalTax) }}</p>
                    </div>
                    <hr class="my-50" />
                    <div class="invoice-total-item">
                      <p class="invoice-total-title">
                        Total:
                      </p>
                      <p class="invoice-total-amount">${{ formatPrice(total) }}</p>
                    </div>
                    <div class="invoice-total-item">
                      <p class="invoice-total-title">
                        Total Paid:
                      </p>
                      <p class="invoice-total-amount">${{ formatPrice(totalPaid) }}</p>
                    </div>
                    <div class="invoice-total-item">
                      <p class="invoice-total-title">
                        Balance Due:
                      </p>
                      <p class="invoice-total-amount">${{ formatPrice(due) }}</p>
                    </div>
                  </div>
                </b-col>
              </b-row>
            </b-card-body>

            <!-- Spacer -->
            <hr class="invoice-spacing" />

            <!-- Note -->
            <b-card-body class="invoice-padding pt-0" v-if="invoiceData.note != null">
              <span class="font-weight-bold">Note: </span>
              <span>
                {{ invoiceData.note }}
              </span>
            </b-card-body>
          </b-card>
        </b-col>

        <!-- Right Col: Card -->
        <b-col cols="12" md="4" xl="3" class="invoice-actions">
          <b-card>
            <!-- Button: Send Invoice -->

            <b-button v-if="invoiceData.status == 'A' && !(userDataRole == 'supermanager' || $Can('order_approve') || userOrderLimit > invoiceData.total)" v-ripple.400="'rgba(255, 255, 255, 0.15)'" @click="updateStatus('B')" variant="primary" class="mb-75" block>
              Send to Manager
            </b-button>

            <b-button v-if="invoiceData.status == 'B' || (invoiceData.status == 'A' && (userDataRole == 'supermanager' || $Can('order_approve') || userOrderLimit > invoiceData.total))" v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="success" class="mb-75" block @click="updateStatus('F')"> Approve the Order </b-button>

            <b-button  v-if="invoiceData.status == 'B' || (invoiceData.status == 'A' && (userDataRole == 'supermanager' || $Can('order_refuse') || userOrderLimit > invoiceData.total))"  v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="danger" class="mb-75" block @click="updateStatus('G')">
              Refuse the Order
            </b-button>

            <b-button v-if="invoiceData.status == 'G' && (userDataRole == 'supermanager' || $Can('order_edit'))" v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="warning" class="mb-75" block @click="updateStatus('A')">
              Revise the Order
            </b-button>

            <b-button v-if=" invoiceData.status != 'J'  && invoiceData.status != 'B'  && invoiceData.status != 'A'   && (userDataRole == 'supermanager' || $Can('order_cancel'))" v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="danger" class="mb-75" block @click="updateStatus('J')">
              Cancel the Order
            </b-button>

            <b-button v-if="invoiceData.leftTotal > 0 && (invoiceData.status == 'F' || invoiceData.status == 'E' || invoiceData.status == 'D') && (userDataRole == 'supermanager' || $Can('order_add_payment')) " v-b-toggle.sidebar-invoice-add-payment v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="success" class="mb-75" block>
              Add Payment
            </b-button>

            <b-button v-if="invoiceData.status == 'C' && (userDataRole == 'supermanager' || $Can('order_complete')) " v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="success" class="mb-75" block @click="updateStatus('H')">
              Complete
            </b-button>

            <!-- 
          <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" v-b-toggle.sidebar-send-invoice variant="primary" class="mb-75" block>
            Send Invoice
          </b-button> -->

            <!-- Button: DOwnload -->
            <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" variant="secondary" class="mb-75" block @click="downloadInvoice($route.params.id)">
              Download
            </b-button>

            <!-- Button: Print -->
            <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" variant="outline-secondary" class="mb-75" block @click="printInvoice">
              Print
            </b-button>

            <!-- Button: Edit -->
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              variant="outline-secondary"
              class="mb-75"
              block
              :to="{ name: 'expense-edit', params: { id: invoiceData.id } }"
              v-if="$Can('order_edit') &&  invoiceData.status != 'G' && invoiceData.status != 'H' && invoiceData.status != 'J'"
            >
              Edit
            </b-button>
          </b-card>

          <!-- <b-card>
            <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" v-b-toggle.sidebar-send-purchase @click="addNewFile()" size="sm" block variant="primary">
              Add New File
            </b-button>

          
            <div v-for="(data, index) in files" :key="index" class="d-flex justify-content-start align-items-center" :class="index == 0 ? 'mt-2' : 'mt-1'">
              <b-img src="@/assets/images/file.png" class="mr-50" width="40px" />
              <div class="user-page-info pr-1">
                <small>{{ data.fileName }} </small>
              </div>
              <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" variant="primary" target="_blank" :href="fileURL + data.fileLink" class="btn-icon ml-auto" size="sm">
                <feather-icon icon="DownloadIcon" />
              </b-button>
            </div>
 
          </b-card> -->

          <b-card>
            <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" v-b-toggle.sidebar-send-purchase @click="addNewFile()" size="sm" block variant="primary"> Add New File </b-button>

            <div v-for="(data, index) in files" :key="index" class="d-flex justify-content-start align-items-center" :class="index == 0 ? 'mt-2' : 'mt-1'">
              <b-img src="@/assets/images/file.png" @click="fileEdit(data)" class="mr-50 " style="cursor:pointer" width="25px" />
              <div class="user-page-info pr-1" style="width: 100%">
                <small @click="fileEdit(data)" style="cursor:pointer; font-size:.8em">{{ data.fileName }} </small>
              </div>
              <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" variant="primary" target="_blank" :href="fileURL + data.fileLink" style="cursor:alias; padding: 1% 1%; margin-right:2px" class="btn-icon ml-auto" size="sm">
                <feather-icon icon="DownloadIcon" />
              </b-button>

              <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" variant="danger" @click="deleteFile(data)" v-if="userDataRole != 'supermanager'" v-show="invoiceData.status == 'A' || invoiceData.status == 'B'" class="btn-icon ml-auto" style="padding: 1% 1%;" size="sm">
                <feather-icon icon="XIcon" />
              </b-button>

              <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" variant="danger" @click="deleteFile(data)" v-else class="btn-icon ml-auto" style="padding: 1% 1%;" size="sm">
                <feather-icon icon="XIcon" />
              </b-button>
            </div>

            <div v-if="files.length == 0" class="d-flex justify-content-center align-items-center mt-1 mb-1">
              No Files
            </div>
          </b-card>
        </b-col>
      </b-row>

      <b-row class="invoice-preview">
        <b-col cols="12" xl="9" md="8">
          <b-card>
            <b-row>
              <table class="table table-sm">
                <thead>
                  <tr>
                    <th scope="col">Payment Records</th>
                  </tr>
                </thead>
              </table>
            </b-row>

            <b-table striped hover :bordered="true" class="position-relative text-center mt-2" selectable responsive :small="true" head-variant="dark" show-empty empty-text="No matching records found" :fields="paymentFields" :items="invoiceData.payments">
              <template #cell(id)="row">
                <strong class="text-primary">#{{ Number(row.item.id) }}</strong>
              </template>

              <template #cell(invoiceBalance)="row">
                <span>{{ row.item.invoiceBalance ? formatPrice(row.item.invoiceBalance) : 'N/A' }}</span>
              </template>

              <template #cell(paymentAmount)="row">
                <span>{{ row.item.paymentAmount ? formatPrice(row.item.paymentAmount) : 'N/A' }}</span>
              </template>

              <template #cell(email)="row">
                <span>{{ row.item.email ? row.item.email : 'N/A' }}</span>
              </template>
            </b-table>
          </b-card>
        </b-col>
      </b-row>

      <b-modal id="modal-file" cancel-variant="outline-secondary" ok-title="Save File" @ok="handleOk" @show="resetModal" @hidden="resetModal" cancel-title="Close" :no-close-on-backdrop="true" centered title="Add New File">
        <b-form ref="fileRules">
          <b-form-group :state="nameState" invalid-feedback="File Name is required">
            <label for="fileName">File Name:</label>
            <b-form-input id="fileName" type="text" :state="nameState" v-model="modalFileName" required placeholder="File Name" />
          </b-form-group>
          <b-form-group :state="fileState" invalid-feedback="File is required">
            <b-form-file @input="fileSelected" type="file" :state="fileState" required placeholder="Choose a file or drop it here..." drop-placeholder="Drop file here..." />
          </b-form-group>
        </b-form>

        <b-col v-if="percentCompleted != 0 && percentCompleted != 100" class="mt-2">
          <b-progress key="info" animated :value="percentCompleted" show-progress variant="dark" class="'progress-bar-info'" />
        </b-col>
      </b-modal>

      <b-modal id="modal-file-update" cancel-variant="outline-secondary" ok-title="Change File" @ok="handleUpdate" cancel-title="Close" :no-close-on-backdrop="true" centered title="Add New File">
        <b-form ref="fileRules">
          <b-form-group :state="nameState" invalid-feedback="File Name is required">
            <label for="fileName">File Name:</label>
            <b-form-input id="fileName" type="text" :state="nameState" v-model="modalFileName" required placeholder="File Name" />
          </b-form-group>
        </b-form>
        <b-col v-if="percentCompleted != 0 && percentCompleted != 100" class="mt-2">
          <b-progress key="info" animated :value="percentCompleted" show-progress variant="dark" class="'progress-bar-info'" />
        </b-col>
      </b-modal>

      <sidebar-purchase-order-add-payment :invoiceData.sync="invoiceData" />
    </section>
  </b-overlay>
</template>

<script>
import { ref, onUnmounted } from '@vue/composition-api';
import store from '@/store';
import router from '@/router';
import { BRow, BCol, BCard, BCardBody, BTableLite, BCardText, BButton, BAlert, BLink, VBToggle } from 'bootstrap-vue';
import Logo from '@core/layouts/components/Logo.vue';
import Ripple from 'vue-ripple-directive';
import accountingStoreModule from '../accountingStoreModule';

import SidebarPurchaseOrderAddPayment from './SidebarPurchaseOrderAddPayment.vue';
import axiosIns from '@/libs/axios';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';

export default {
  directives: {
    Ripple,
    'b-toggle': VBToggle,
  },
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BTableLite,
    BCardText,
    BButton,
    BAlert,
    BLink,

    Logo,
    accountingStoreModule,
    SidebarPurchaseOrderAddPayment,

    ToastificationContent,
  },



  setup() {
    const ACCOUNTING_APP_STORE_MODULE_NAME = 'accounting';

    // Register module
    if (!store.hasModule(ACCOUNTING_APP_STORE_MODULE_NAME)) store.registerModule(ACCOUNTING_APP_STORE_MODULE_NAME, accountingStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(ACCOUNTING_APP_STORE_MODULE_NAME)) store.unregisterModule(ACCOUNTING_APP_STORE_MODULE_NAME);
    });
  },

  data() {
    var userDataRole = JSON.parse(localStorage.getItem('userData')).role;
    var userOrderLimit = JSON.parse(localStorage.getItem('userData')).orderLimit;

    return {
      userDataRole,
      userOrderLimit,
      modalFileName: null,
      modalFile: null,
      download: false,
      percentCompleted: 0,
      formShow: false,
      modalId: null,
      files: [],
      dropCustomers: [],
      dropInventorys: [],
      dropItems: [],
      tableColumns: [
        { key: 'itemTitle', label: 'Description', sortable: true, class: 'text-left', thStyle: 'width: 30%' },
        { key: 'vehicle', label: 'Vehicle', sortable: true, class: 'text-left', thStyle: 'width: 15%' },
        { key: 'cost', label: 'Cost', sortable: true, class: 'text-center', thStyle: 'width: 15%' },
        { key: 'qty', label: 'QTY', sortable: true, class: 'text-center', thStyle: 'width: 5%' },
        { key: 'gst', label: 'GST', sortable: true, class: 'text-center', thStyle: 'width: 5%' },
        { key: 'pst', label: 'PST', sortable: true, class: 'text-center', thStyle: 'width: 5%' },
        { key: 'discount', label: 'Discount', sortable: true, class: 'text-center', thStyle: 'width: 10%' },
        { key: 'total', label: 'Total', sortable: true, class: 'text-center', thStyle: 'width: 15%' },
      ],

      paymentFields: [
        { key: 'id', label: 'PAYMENT ID', class: 'text-center', thStyle: 'width: 15%' },
        { key: 'paymentDate', label: 'DATE', class: 'text-center', thStyle: 'width: 15%' },
        { key: 'invoiceBalance', label: 'BALANCE', class: 'text-center', thStyle: 'width: 15%' },
        { key: 'paymentAmount', label: 'PAYMENT AMOUNT', class: 'text-center', thStyle: 'width: 15%' },
        { key: 'paymentMethod', label: 'PAYMENT METHOD', class: 'text-center', thStyle: 'width: 15%' },
      ],

      invoiceData: {
        items: [{ itemTitle: null, cost: null, qty: null, description: null, inventoryId: null, gst: null, pst: null, discount: null }],
        customerId: null,
        id: null,
        date: null,
        salesPerson: null,
        note: null,
        status: null,
        vehicleId: null,
        total: null,
        leftTotal: null,
        payments: [],
      },

      baseURL: store.state.app.baseURL,
      fileURL: store.state.app.purchaseOrderURL,

      formShow: false,

      status: null,

      options: {
        number: {
          numeral: true,
          numeralThousandsGroupStyle: 'thousand',
        },

        percent: {
          numeral: true,
          numeralPositiveOnly: true,
          blocks: [2],
          prefix: '% ',
          rawValueTrimPrefix: true,
          numeralIntegerScale: 2,
        },
      },
    };
  },

  directives: {
    Ripple,
    'b-toggle': VBToggle,
  },
  computed: {
    nameState() {
      return this.modalFileName != null && this.modalFileName != '' ? true : false;
    },

    fileState() {
      return this.modalFile != null && this.modalFile != '' ? true : false;
    },

    totalPaid() {
      var result = 0;
      this.invoiceData.payments.forEach((element) => {
        result = result + element.paymentAmount;
      });
      return result;
    },

    subTotal() {
      var result = 0;
      this.invoiceData.items.forEach((element) => {
        result = result + element.cost * element.qty;
      });
      return result;
    },

    due() {
      return this.total - this.totalPaid;
    },

    totalDiscount() {
      var result = 0;
      this.invoiceData.items.forEach((element) => {
        result += (element.cost * element.qty * element.discount) / 100;
      });
      return result;
    },

    totalTax() {
      var result = 0;

      var gst = 0;
      var pst = 0;
      this.invoiceData.items.forEach((element) => {
        if (element.gst != null && element.gst != '' && element.gst != 0) {
          gst = (element.cost * element.qty * parseFloat(element.gst)) / 100;
        }

        if (element.pst != null && element.pst != '' && element.pst != 0) {
          pst = (element.cost * element.qty * parseFloat(element.pst)) / 100;
        }

        result = result + gst + pst;

        gst = 0;
        pst = 0;
      });
      return result ? result : 0;
    },

    total() {
      var result = this.subTotal - this.totalDiscount + this.totalTax;

      return result ? result : 0;
    },
  },

  methods: {
    lineTotal(cost,qty,gst,pst,discount){
      let costs = cost  ? cost : 0;
      let qtys = qty ? qty : 0;
      let gsts = gst ? gst : 0;
      let psts = pst ? pst : 0;
      let discounts = discount ? discount : 0;
      let subTotal = costs*qtys;
      let totalTax = subTotal*(gsts+psts)/100;
      let totalDiscount = subTotal*discounts/100;

      return  subTotal - totalDiscount + totalTax;

      
    },
    updateStatus(status) {
      this.formShow = true;
      store
        .dispatch('accounting/updateStatus', { id: router.currentRoute.params.id, status: status })
        .then((response) => {
          this.getByIdExpense();
        })
        .catch((error) => {
          console.log(error);
          this.formShow = false;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Something went wrong',
              text: 'Please try again or report an issue to support',
              icon: 'ThumbsDownIcon',
              variant: 'danger',
            },
          });
        });
    },

    printInvoice() {
      window.print();
    },

    downloadInvoice(val) {
      this.download = true;
      this.formShow = true;
      store.dispatch('accounting/pdfdownloadPurchaseOrder', val).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'Purchase_Order_' + Number(val) + '.pdf');
        document.body.appendChild(link);
        link.click();
        this.formShow = false;

        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Successful',
            text: '✔️ Purchaser Order is downloaded!',
            icon: 'ThumbsUpIcon',
            variant: 'success',
          },
        });

        this.download = false;
      });
    },

    getByIdExpense() {
      this.formShow = true;

      store
        .dispatch('accounting/fetchExpenseById', { id: router.currentRoute.params.id })
        .then((response) => {
          this.invoiceData = response.data;
          this.invoiceData.leftTotal = this.formatPrice(this.invoiceData.leftTotal);
          this.files = response.data.get_files;
          this.formShow = false;
        })
        .catch((error) => {
          console.log(error);
          this.formShow = false;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Something went wrong',
              text: 'Please try again or report an issue to support',
              icon: 'ThumbsDownIcon',
              variant: 'danger',
            },
          });
          // router.push({ name: 'quote-list' });
        });
    },

    formatPrice(value, val) {
      if (value != null) {
        let val = (value / 1).toFixed(2).replace(',', '.');
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      }
    },

    addNewFile() {
      this.$bvModal.show('modal-file');
    },

    fileEdit(data) {
      this.modalId = data.id;
      if (this.userDataRole == 'supermanager') {
        this.modalFileName = data.fileName;

        this.$bvModal.show('modal-file-update');
      } else if (this.invoiceData.status == 'A' || this.invoiceData.status == 'B') {
        this.modalFileName = data.fileName;
        this.$bvModal.show('modal-file-update');
      }
    },

    deleteFile(data) {
      this.$swal({
        title: 'Are you sure?',
        text: '',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-danger ml-1',
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.formShow = true;

          axiosIns
            .delete(`deletePurchaseOrderFile/${data.id}`)
            .then((res) => {
              var tempList = [];
              this.files.forEach((element) => {
                if (element.id != data.id) {
                  tempList.push(element);
                }
              });

              this.files = tempList;
              this.formShow = false;
            })
            .catch((error) => {
              console.log('error delete ', error);
            });
        }
      });
    },

    handleOk(bvModalEvent) {
      // Prevent modal from closing
      bvModalEvent.preventDefault();
      // Trigger submit handler
      if (this.percentCompleted == 100) {
        var data = {
          expenseId: this.invoiceData.id,
          fileName: this.modalFileName,
          fileLink: this.modalFile,
        };

        this.modalOk(data);
      }
    },

    handleUpdate(bvModalEvent) {
      // Prevent modal from closing
      bvModalEvent.preventDefault();
      // Trigger submit handler
      this.modalUpdate();
    },

    resetModal() {
      this.modalFileName = null;
      this.modalFile = null;
    },

    modalOk(data) {
      if (!this.checkFormValidity()) {
        return;
      }

      this.$nextTick(() => {
        this.$bvModal.hide('modal-file');
      });

      this.formShow = true;

      axiosIns
        .post('savePurchaseOrderFile', data)
        .then((response) => {
          this.files.push({
            id: response.data.id,
            fileName: response.data.fileName,
            fileLink: response.data.fileLink,
          });

          this.modalFileName = null;
          this.modalFile = null;

          this.formShow = false;
        })
        .catch((error) => {
          this.formShow = false;
          console.log(error);
        });
    },

    modalUpdate(val) {
      if (!this.checkFormValidity()) {
        return;
      }

      this.$nextTick(() => {
        this.$bvModal.hide('modal-file-update');
      });

      this.formShow = true;

      var data = {
        id: this.modalId,
        fileName: this.modalFileName,
        fileLink: this.modalFile,
      };

      axiosIns
        .put('updatePurchaseOrderFile', data)
        .then((response) => {
          this.modalFileName = null;
          this.modalFile = null;

          this.files.forEach((file) => {
            if (file.id == this.modalId) {
              file.fileName = response.data.fileName;
            }
          });

          this.formShow = false;
        })
        .catch((error) => {
          this.formShow = false;
          console.log(error);
        });
    },

    fileSelected(event) {
      //  this.formShow = true;
      let formData = new FormData();
      formData.append('file', event);

      const config = {
        onUploadProgress: function(progressEvent) {
          this.percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
        }.bind(this),
      };

      axiosIns
        .post('uploadPurchaseOrderFile', formData, config)
        .then((response) => {
          this.modalFile = response.data;

          // this.tempImage = null;
          // this.selectedImage = null;
          this.formShow = false;
        })
        .catch((error) => {
          this.formShow = false;
          console.log(error);
        });
    },

    checkFormValidity() {
      const nameValid = this.$refs.fileRules.checkValidity();
      const fileValid = this.$refs.fileRules.checkValidity();

      return fileValid && nameValid;
    },
  },

  created() {
    this.getByIdExpense();
  },
};
</script>

<style lang="scss" scoped>
@import '~@core/scss/base/pages/app-invoice.scss';
</style>

<style lang="scss">
@media print {
  // Global Styles
  body {
    background-color: transparent !important;
  }
  nav.header-navbar {
    display: none;
  }
  .main-menu {
    display: none;
  }
  .header-navbar-shadow {
    display: none !important;
  }
  .content.app-content {
    margin-left: 0;
    padding-top: 2rem !important;
  }
  footer.footer {
    display: none;
  }
  .card {
    background-color: transparent;
    box-shadow: none;
  }
  .customizer-toggle {
    display: none !important;
  }

  // Invoice Specific Styles
  .invoice-preview-wrapper {
    .row.invoice-preview {
      .col-md-8 {
        max-width: 100%;
        flex-grow: 1;
      }

      .invoice-preview-card {
        .card-body:nth-of-type(2) {
          .row {
            > .col-12 {
              max-width: 50% !important;
            }

            .col-12:nth-child(2) {
              display: flex;
              align-items: flex-start;
              justify-content: flex-end;
              margin-top: 0 !important;
            }
          }
        }
      }
    }

    // Action Right Col
    .invoice-actions {
      display: none;
    }
  }
}
</style>
